<template>
    <div>
        <highcharts v-if="loaded" :options="chartOptions" ref="chart"/>
        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import exportingInit from "highcharts/modules/exporting"
import Highcharts from "highcharts"
import dashboardChartMixin from '@/mixins/dashboardChartMixin'

exportingInit(Highcharts)

export default {
    name: 'StartupsStageColumnChart',
    mixins: [dashboardChartMixin],
    components: {
        highcharts: Chart
    },
    data() {
        return {
            loaded: false,
            chartName: "StartupsByStageColumn",
            chartData: [],
            chartOptions: {
                chart: {
                    type: "column",
                    style: {
                        fontFamily: '"Overpass", sans-serif;',
                    },
                },
                title: {
                    text: "Startups By Stage"
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "Number of startups added",
                        align: "high"
                    },
                },
                tooltip: {
                    formatter: function () {
                        return this.point.label
                    }
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                        },
                        events: {
                            click: (e) => {
                                this.openStartupsModal(e.point.category)
                            }
                        }
                    }
                },
                series: [
                    {
                        name: "Stage",
                        data: [],
                        colorByPoint: true,
                    }
                ]
            }
        }
    },
    methods: {
        fetchChartData(initialCall = false) {
            if (!initialCall) {
                this.$refs.chart.chart.showLoading()
            }

            this.getChartData(this.chartName).then(response => {
                this.chartData = response.data
                this.formatAndSetChartData()

                if (!initialCall) {
                    this.$refs.chart.chart.hideLoading()
                } else {
                    this.loaded = true
                }
            })
        },

        formatAndSetChartData() {
            this.chartOptions.series[0].data = this.chartData
            this.chartOptions.xAxis.categories = this.chartData.map(item => item.stage)
        },

        openStartupsModal(stage) {
            this.$emit('showStartups', [this.chartName, `Startups of ${stage} stage`, stage])
        },
    }
}
</script>
